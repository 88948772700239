<template>
  <div class="create-page">
    <PreviewSpiner v-if="!ready" class="loader"/>
    <div
      class="click-to-start"
      v-if="!isStartCreating && !missions.length"
      @click="startCreating"
    >
      click here to start
    </div>
    <CButton
      v-if="!isStartCreating && missions.length"
      variant="outline"
      class="btn zq--responsive-button finish-btn"
      @click="finishMissionCreation"
    >
      {{$t('buttons.finish')}}
    </CButton>
    <CreateMissionsGraph
      v-if="graphData"
      :graphData="graphData"
      :missionsData="missions"
    />
    <div class="creating-block" v-if="isStartCreating" :class="{ visible: isStartCreating, hidden: !isStartCreating }">
      <div class="creating-block_header">
        <div class="creating-block_header_title">
          {{ stepTitle }}
        </div>
        <div v-if="isShowWarning" class="warning">All required fields must be filled in</div>
        <div class="creating-block_header_actions">
          <CButton
            variant="outline"
            class="zq--custom-button zq--custom-button--responsive mr-4"
            @click="showCancelModal"
          >
            {{$t('buttons.cancel')}}
          </CButton>

          <div class="d-flex align-items-center">
            <CButton
              variant="outline"
              class="zq--custom-button zq--custom-button--responsive mr-2"
              @click="prevStep"
              :disabled="currentStep === 0"
            >
              prev
            </CButton>
            <CButton
              variant="outline"
              class="zq--custom-button zq--custom-button--responsive"
              @click="handleClickNext"
            >
              {{ totalStep === currentStep ? 'save' : 'next' }}
            </CButton>
          </div>

        </div>
      </div>
      <div class="d-flex align-items-center justify-center w-100 pl-3 pr-3">
        <StepsBlockComponent
          :steps="steps"
          :currentStep="currentStep"
          @changeStep="changeStep"
        />
      </div>

      <SettingsStep
        v-if="currentStep === 0"
        :list="formList"
        :descriptions="descriptions"
        :texts="texts"
        :settingsData="settingsData"
        :schedulingData="schedulingData"
        :entrantsData="entrantsData"
        @updateSettingsData="updateSettingsData"
        @updateSchedulingData="updateSchedulingData"
        @updateEntrantsData="updateEntrantsData"
        @updateConstraints="getConstraints"
        @updateCanParticipate="updateCanParticipate"
        @updateLimitParticipants="updateLimitParticipants"
      />
      <div v-if="currentStep === 1" class="creating-step-wrapper pt-4">
        <RewardsCreation
          @updateRewardData="updateRewardData"
          :rewardsData="rewardsData"
          :entityType="'Achievement'"
          :texts="texts"
        />
      </div>

      <div v-if="currentStep === 2" class="creating-step-wrapper">
        <CreateRules
          v-if="currentStep === 2"
          context="achievement"
          @updateRulesData="updateRulesData"
          @updateStrategiesOperatorData="updateStrategiesOperatorData"
          @updateStrategiesPointsValueUpperData="updateStrategiesPointsValueUpperData"
          @updateStrategiesPointsValueData="updateStrategiesPointsValueData"
          @updateDataFromTemplate="updateDataFromTemplate"
          :rulesQueryData="rulesQueryData"
          :stepNumber="currentStep + 1"
          :strategiesData="strategies.pointsStrategy"
          :isMission="true"
        />
      </div>

      <div v-if="currentStep === 3" class="creating-step-wrapper pt-4 pr-4 pl-4 pb-4">
        <AddProducts
          v-if="currentStep === 3"
          :productsData="productsData"
          :isAdvanced="true"
          :descriptions="productsDescriptions"
          :stepNumber="currentStep + 1"
          @updateProductsData="updateProductsData"
          @updateIsAllProductsInclude="updateIsAllProductsInclude"
          @updateCurrentTypes="updateProductsCurrentTypes"
          :isHeader="false"
        />
      </div>

      <div v-if="currentStep === 4" class="creating-step-wrapper pt-4">
        <CreateTranslations
          v-if="currentStep === 4"
          :entityData="settingsData"
          :translatableFields="translatableFields"
          :translationsData="translationsData"
          @updated="updateTranslationsData"
          :stepNumber="currentStep + 1"
          :isHeaderTitle="false"
        />
      </div>

      <div v-if="currentStep === 5" class="creating-step-wrapper pt-4">
        <SummaryStep
          v-if="currentStep === 5"
          :settingsData="settingsData"
          :rulesData="rulesData"
          :dependantOnData="dependantOnData"
          :schedulingData="schedulingData"
          :rewardsData="rewardsData"
          :showEmptyRewardsData="true"
          :translationsData="translationsData"
          :entrantsData="entrantsData"
          :stepNumber="currentStep + 1"
          :model="model"
          :translatableFields="translatableFields"
          :strategiesData="strategies"
          :productsData="productsData"
        />
      </div>

    </div>
    <ListOfCloningMissions
      v-if="isMissionCloneModal"
      :missionsList="missions"
      @setCloneData="setCloneData"
      @closeModal="closeMissionCloneModal"
    />
    <Modal
      :modalShow="cloneModal || cancelMissionCreationModal"
      :messageGeneral="cloneModal ? '' : 'You are about to cancel mission creation. Are you sure?'"
      :title="cloneModal ? texts.cloneTitle : 'Cancel mission creation'"
      :cancelBtnLabel="cloneModal ? 'Create a mission' : 'Close'"
      :successBtnLabel="cloneModal ? 'Clone a mission' : 'I\'m sure'"
      @doFunction="handleDoFunction"
      v-on:toggle-modal="handleToggleModal"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import fieldHelpers from '@/utils/ZiqniFieldHelper';
import achievementFields from '@/generated/ziqni/store/modules/achievements/fields';
import { achievements } from '@/config/descriptions/achievements.json';
import { achievementsTexts } from '@/config/pageTexts/achievements.json';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import { cloneDeep, startCase } from 'lodash';
import { achievementQuery } from '@/helpers/rules/achievementQuery';
import { stepSubTitles } from '@/config/pageTexts/stepSubTitles.json';
import StepsBlockComponent from '@/components/mission/StepsBlockComponent.vue';
import SettingsStep from '@/components/mission/SettingsStep.vue';
import CreateRewards from '@/shared/components/supportModels/rewards/AddRewards.vue';
import CreateRules from '@/shared/components/supportModels/rules/CreateRules.vue';
import AddProducts from '@/shared/components/supportModels/products/AddProducts.vue';
import { competitions } from '@/config/descriptions/competitions.json';
import CreateTranslations from '@/shared/components/supportModels/translations/CreateTranslations.vue';
import SummaryStep from '@/shared/components/steps/SummaryStep.vue';
import RewardsCreation from '@/components/mission/RewardsCreation.vue';
import { translationsTransform } from '@/utils/translationsUtils';
import MissionBlock from '@/components/mission/MissionBlock.vue';
import Modal from '@/shared/UI/Modal.vue';
import CreateMissionsGraph from '@/components/mission/CreateMissionsGraph.vue';
import axios from 'axios';
import PreviewSpiner from '@/shared/UI/Spiner.vue';
import ListOfCloningMissions from '@/components/mission/ListOfCloningMissions.vue';

export default {
  name: 'CreateMission',
  components: {
    ListOfCloningMissions,
    PreviewSpiner,
    Modal,
    MissionBlock,
    RewardsCreation,
    SummaryStep,
    CreateTranslations,
    AddProducts,
    CreateRules,
    CreateRewards,
    SettingsStep,
    StepsBlockComponent,
    CreateMissionsGraph
  },
  provide() {
    return {
      model: this.model,
    };
  },
  data() {
    return {
      model: 'achievements',
      isStartCreating: false,
      currentStep: 0,
      totalStep: 0,
      descriptions: {
        ...achievements.create,
      },
      texts: {
        ...achievementsTexts,
      },
      steps: [],
      stepKeys: [],
      firstStep: {
        title: 'Settings',
        subTitle: stepSubTitles.settings,
        step: 0,
      },
      lastStep: {
        title: 'Summary',
        subTitle: stepSubTitles.summary,
        key: 'summary',
        step: 2,
      },
      formList: [],
      settingsData: {},
      translationsData: {},
      translationsMap: {},
      requiredFields: [],
      translatableFields: [],
      dependantOnData: {
        isMission: true,
        currentTypes: {},
        formData: {
          shouldMatchAtLeast: null,
          dependantOn: {
            must: [],
            mustNot: [],
            should: [],
            shouldMatchAtLeast: 0,
          }
        },
        selectedData: []
      },
      dependantOnDataValidate: null,
      schedulingData: {
        scheduleOccurrencesLimit: 1,
        maxNumberOfIssues: null,
        scheduleType: '',
        constraints: [],
        endDate: null,
        startDate: new Date(),
        every: [],
      },
      entrantsData: {
        currentTypes: {},
        selectedData: [],
        limitParticipants: false,
        canParticipate: true,
        optinRequiredForEntrants: false,
        formData: {
          maxNumberOfEntrants: null,
          minNumberOfEntrants: 0,
          shouldMatchAtLeast: 1,
          must: [],
          mustNot: [],
          should: []
        }
      },
      constraints: [],
      schedulingDataValid_startDate: null,
      schedulingDataValid_every: null,
      schedulingDataValid_scheduleOccurrencesLimit: null,
      schedulingDataValid_scheduleType: null,
      rewardsData: [],
      isRewardSaved: true,
      transformedRewards: [],
      rulesData: cloneDeep(achievementQuery),
      entrantsStep: {
        label: 'Entrants',
        key: 'entrants'
      },
      productTagsStep: {
        label: 'Products',
        key: 'productIds',
        subTitle: 'Products tags',
      },
      stepsOrder: {
        // scheduling: 1,
        // entrants: 2,
        rewards: 1,
        rules: 2,
        productIds: 3,
        translations: 4,
      },
      rulesQueryData: {},
      isCompleted: false,
      ready: true,
      strategies: {
        pointsStrategy: {
          operator: 'GreaterThanEquals',
          pointsValueUpper: null,
          pointsValue: 100
        }
      },
      productsData: {
        currentTypes: {},
        isAllProductsInclude: true,
        selectedData: [],
        formData: {
          shouldMatchAtLeast: 1,
          dependantOn: {
            must: [],
            mustNot: [],
            should: []
          }
        }
      },
      missions: [],
      graphData: null,
      cloneModal: false,
      cancelMissionCreationModal: false,
      isMissionCloneModal: false,
      isShowWarning: false,
      warningTimeout: null,
      missionId: null,
    };
  },
  computed: {
    ...mapGetters('achievements', ['achievements', 'message', 'loading']),
    ...mapGetters('languages', { storeLanguages: 'languages' }),
    stepTitle() {
      return this.steps.find(step => step.step === this.currentStep)?.title || '';
    },
    productsDescriptions() {
      return {
        ...competitions.create.multi.products,
      };
    }
  },
  watch: {
    entrantsData: {
      deep: true,
      handler(val) {

      }
    }
  },
  mounted() {
    document.addEventListener('optionSelected', this.handleOptionSelected);

    this.observer = new MutationObserver((mutations) => {
      mutations.forEach(() => {
        this.applyBorderTopStyle();
      });
    });

    this.observer.observe(this.$el, {
      childList: true,
      subtree: true,
    });

  },
  beforeDestroy() {
    document.removeEventListener('optionSelected', this.handleOptionSelected);

    if (this.observer) {
      this.observer.disconnect();
    }
  },
  created() {
    routerBreadcrumbs(this.$router.currentRoute);
  },
  methods: {
    ...mapActions('achievements', [
      'handleGetAchievements_item',
      'handleCreateAchievements',
      'handleGetAchievementToClone',
      'handleGetAchievements'
    ]),
    ...mapActions('tags', ['handleGetTags', 'handleGetTagsByQuery']),
    ...mapActions('rewardTypes', ['handleGetRewardTypes']),
    ...mapActions('languages', ['handleGetLanguages']),
    ...mapActions('products', ['handleGetProducts']),
    ...mapActions('rewards', ['handleGetRewardsByQuery']),
    startCreating() {
      this.currentStep = 0;
      this.isStartCreating = true;
      this.initialize();
    },
    applyBorderTopStyle() {
      const formContent = this.$el.querySelector('.form-content.content');
      if (formContent) {
        formContent.style.borderTop = 'none';
      }
    },
    handleOptionSelected(event) {
      const { nodeId, option } = event.detail;

      const obj = { type: option, missionId: nodeId };
      this.addConnection(obj);
    },
    initialize() {

      this.formList = fieldHelpers.prepareCreateFormList(
        achievementFields,
        achievementsTexts.createPage,
        achievements.create
      );

      this.formList = this.formList.filter(item => !['maxNumberOfIssues'].includes(item.key));

      let formSteps = [];

      this.formList.forEach(field => {
        if (field.type.indexOf('_OBJECT') !== -1) {
          formSteps.push(field);
        }
        if (field.required && field.key !== 'constraints') {
          if (field.key === 'scheduling') {
            this.requiredFields.push('scheduleType');
          } else {
            this.requiredFields.push(field.key);
          }
        }
      });

      formSteps.push(this.entrantsStep, this.productTagsStep);
      formSteps = formSteps.filter(step => step.key !== 'dependantOn' && step.key !== 'entrants' && step.key !== 'scheduling');

      if (formSteps.length) {
        let objectTypes = [];
        formSteps.forEach(step => {
          objectTypes.push(step.type);
        });
        this.formList = this.formList.filter(formItem => {
          return !objectTypes.includes(formItem.type);
        });

        this.steps.push(this.firstStep);

        formSteps.forEach(step => {
          this.steps.push({
            title: startCase(step.label),
            subTitle: stepSubTitles[step.key],
            key: step.key,
            step: this.stepsOrder[step.key],
          });
          this.stepKeys.push(step.key);
        });

        this.steps.sort((a, b) => a.step > b.step ? 1 : -1);

        this.translatableFields = achievementFields.baseFields.translatableFields;

        this.lastStep.step = this.steps.length;
        this.steps.push(this.lastStep);
        this.totalStep = formSteps.length + 1;
      }
    },
    prevStep() {
      if (this.totalStep > 0) {
        this.currentStep -= 1;
      }
    },
    nextStep() {
      let invalidFields = this.getInvalidFields(true);

      if (!invalidFields.length && this.dependantOnDataValidate !== false) {
        this.currentStep += 1;
      } else {
        this.setInvalidFields(invalidFields);
        this.isShowWarning = true;

        if (this.warningTimeout) {
          clearTimeout(this.warningTimeout);
        }

        this.warningTimeout = setTimeout(() => {
          this.isShowWarning = false;
          this.warningTimeout = null;
        }, 3000);
      }
    },
    handleClickNext() {
      if (this.currentStep === this.getStepNumber('summary')) {
        this.isCompleted = true;
      }

      if (this.isCompleted) {
        this.createEntity();
      } else {
        this.nextStep();
      }
    },
    changeStep(targetStep) {
      if (targetStep < this.currentStep) {
        this.currentStep = targetStep;
      } else if (targetStep === this.currentStep + 1) {
        this.nextStep();
      }
    },
    getStepNumber(key) {
      let entityObject = this.steps.find(step => step.key === key);

      if (entityObject !== undefined && entityObject.hasOwnProperty('step')) {
        return entityObject.step;
      } else {
        return -1;
      }
    },
    getInvalidFields() {
      let result = [];

      if (Object.keys(this.settingsData).length === 0 || this.settingsData.name === null || this.settingsData.name === '') {
        result.push('name');
      }

      if (this.schedulingData['scheduleType'] === null || this.schedulingData['scheduleType'] === '') {
        result.push('scheduleType');
      }

      return result;
    },
    setInvalidFields(invalidFields) {
      invalidFields.forEach(invalidField => {
        let invalidFieldElement = document.getElementsByName(invalidField)[0];
        let invalidElement = invalidFieldElement;

        if (!invalidFieldElement.classList.contains('zq--form-row')) {
          invalidElement = invalidFieldElement.parentNode;
        }
        invalidElement.classList.add('zq-invalid');
      });
    },
    updateSettingsData(val) {
      this.settingsData = val;
    },
    updateSchedulingData(val) {
      this.schedulingData = val;
    },
    updateEntrantsData(val) {
      this.entrantsData = val;
    },
    getConstraints(val) {
      this.entrantsData.optinRequiredForEntrants = !!val.includes('optinRequiredForEntrants');
      this.constraints = val;
    },
    updateLimitParticipants(val) {
      this.entrantsData.limitParticipants = val;
    },
    updateCanParticipate(val) {
      this.entrantsData.canParticipate = val;
    },
    getMemberTagsFilter(formData) {
      return {
        must: formData.must,
        mustNot: formData.mustNot,
        should: formData.should,
        shouldMatchAtLeast: formData.shouldMatchAtLeast,
      };
    },
    updateRewardData(rewards) {
      this.rewardsData = rewards;
      let localRewards = cloneDeep(rewards);
      let transformedRewards = [];
      if (localRewards.length) {
        localRewards.forEach(reward => {
          reward.rewardTypeId = reward.rewardType.id;
          delete reward.rewardType;
          delete reward.id;
          delete reward.spaceName;
          delete reward.created;
          delete reward.entityType;
          delete reward.entityId;
          delete reward.translatableFields;
          transformedRewards.push(reward);
        });
      }
      this.transformedRewards = transformedRewards;
    },
    validateRewardSaved(isRewardSaved) {
      this.isRewardSaved = isRewardSaved;
    },
    updateRulesData(val) {
      this.rulesQueryData = cloneDeep(val);
      for (let key in val) {
        if (val[key]) {
          this.rulesData[key] = val[key];
        }
      }
    },
    updateStrategiesOperatorData(val) {
      this.strategies.pointsStrategy.operator = val.key;
    },
    updateStrategiesPointsValueUpperData(val) {
      this.strategies.pointsStrategy.pointsValueUpper = val;
    },
    updateStrategiesPointsValueData(val) {
      this.strategies.pointsStrategy.pointsValue = val;
    },
    updateDataFromTemplate(val) {
      this.rulesQueryData = cloneDeep(val);
      for (let key in this.rulesData) {
        if (val[key]) {
          if (val[key].id) {
            delete val[key].id;
          }
          this.rulesData[key] = val[key];
        } else {
          this.rulesData[key] = achievementQuery[key];
        }
      }
    },
    updateProductsData(val) {
      this.productsData = val;
    },
    updateIsAllProductsInclude(val) {
      this.productsData.isAllProductsInclude = val;
    },
    updateProductsCurrentTypes(val) {
      this.productsData.currentTypes = val;
    },
    getProductTagsFilterTagsFilter() {
      return {
        must: this.productsData.formData.dependantOn.must.length ? this.productsData.formData.dependantOn.must : [],
        mustNot: this.productsData.formData.dependantOn.mustNot.length ? this.productsData.formData.dependantOn.mustNot : [],
        should: this.productsData.formData.dependantOn.should.length ? this.productsData.formData.dependantOn.should : [],
        shouldMatchAtLeast: this.productsData.formData.dependantOn.shouldMatchAtLeast,
      };
    },
    async getProductIds() {
      let productIds = [];

      if (this.productsData.isAllProductsInclude) {
        let products = await this.handleGetProducts({ idArray: [] });
        products.forEach(item => {
          productIds.push(item.id);
        });
      } else {
        if (this.productsData.selectedData.length && Object.hasOwn(this.productsData.selectedData[0], 'productRefId')) {
          this.productsData.selectedData.forEach(product => {
            productIds.push(product.id);
          });
        }
      }

      return productIds;
    },
    updateTranslationsData(obj) {
      this.translationsMap = obj.map;
      this.translationsData = obj.val;
    },
    getRules() {
      let rules = [];
      const rulesData = cloneDeep(this.rulesData);
      for (const action in rulesData) {
        if (rulesData[action].then && rulesData[action].then.length) {
          rulesData[action].then = rulesData[action].then.map(t => {
            if (!t.arguments) {
              t.arguments = [];
            }
            return t;
          });
        }

        let ruleObject = {};

        ruleObject.entityId = '';
        ruleObject.action = action;
        ruleObject.context = 'achievement';
        ruleObject.rules = rulesData[action];
        ruleObject.rules.type = 'condition';
        ruleObject.rules.lineNumber = 1;

        rules.push(ruleObject);
      }

      return rules;
    },
    async createEntity() {
      try {
        let formData = { ...this.settingsData };

        delete formData.constraints;
        formData.addConstraints = [];

        if (Object.keys(this.translationsData).length) {
          formData.translations = translationsTransform(this.translationsData, this.translationsMap);
        }

        if (this.transformedRewards.length) {
          formData.rewards = this.transformedRewards;
        }

        if (Object.keys(this.schedulingData).length && this.schedulingData.scheduleType) {
          formData.scheduling = { ...this.schedulingData };
          if (this.schedulingData.maxNumberOfIssues) {
            formData.maxNumberOfIssues = this.schedulingData.maxNumberOfIssues;
          }
          delete formData.scheduling.maxNumberOfIssues;
        }

        if (Object.keys(this.rulesData).length) {
          formData.rules = this.getRules();
        }

        if (this.dependantOnData.isMission) {
          this.constraints.push('mission');
        } else if (Object.keys(this.dependantOnData).length && this.dependantOnData.formData) {

          let isDependantOn = false;
          const index = formData.addConstraints.indexOf('mission');

          if (index !== -1) {
            formData.addConstraints.splice(index, 1);
          }

          const thisConstraintsIndex = this.constraints.indexOf('mission');
          if (thisConstraintsIndex !== -1) {
            this.constraints.splice(thisConstraintsIndex, 1);
          }

          for (let key in this.dependantOnData.formData.dependantOn) {
            if (this.dependantOnData.formData.dependantOn[key].length) {
              isDependantOn = true;
            }
          }
          if (isDependantOn) {
            formData.achievementDependencies = this.dependantOnData.formData.dependantOn;
          }
        }

        if (formData.key && typeof formData.key === 'object') {
          formData.name = formData.key.name;
          formData.key = formData.key.key;
        }

        formData.memberTagsFilter = this.getMemberTagsFilter(this.entrantsData.formData);

        if (this.constraints.length) {
          formData.addConstraints = this.constraints;
        }

        formData.strategies = this.strategies;

        if (this.productsData.isAllProductsInclude) {
          if (!formData.addConstraints.includes('allProducts')) {
            formData.addConstraints.push('allProducts');
          }

          formData.productIds = null;
          formData.productTagsFilter = null;
        } else {
          const index = formData.addConstraints.indexOf('allProducts');
          if (index !== -1) {
            formData.addConstraints.splice(index, 1);
          }

          formData.productIds = await this.getProductIds();
          if (!formData.productIds.length) {
            formData.productTagsFilter = this.getProductTagsFilterTagsFilter();
          }
        }

        if (formData.scheduling && formData.scheduling.onlyAggregateOnActiveDays) {
          delete formData.scheduling.onlyAggregateOnActiveDays;
          formData.scheduling.constraints.push('onlyAggregateOnActiveDays');
        }

        const body = [JSON.parse(JSON.stringify(formData))];
        this.ready = false;

        const data = await this.handleCreateAchievements({ createAchievementRequestArray: body });

        if (data.length) {
          const missionData = await this.handleGetAchievements_item([data[0].id], 1, 0);

          if (missionData.length) {
            setTimeout(async () => {
              const rewardData = await this.handleGetRewardsByQuery({
                queryRequest: {
                  should: [{
                    queryField: 'entityId',
                    queryValues: [data[0].id]
                  }],
                  shouldMatch: 1
                }
              });

              missionData[0].rewards = rewardData;
              this.missions.push(missionData[0]);

              this.resetState();
              this.missionId = this.missions.find(item => !item.achievementDependencies).id;

              const token = localStorage.getItem('vue-token');

              const path = 'https://api.ziqni.com/graphs';

              const entityGraphRequest = {
                ids: [this.missionId],
                constraints: [],
                languageKey: '',
                includes: [],
                entityType: 'Achievement'
              };

              const { data: graphData } = await axios.post(path, entityGraphRequest, {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${ token }`
                }
              });

              this.graphData = graphData.data;

              this.isStartCreating = false;
              this.ready = true;
            }, 2000);
          } else {
            console.log('Mission not found');
            this.ready = true;
          }

        } else {
          console.log('Something went wrong with the creation');
          this.ready = true;
        }

      } catch (error) {
        this.ready = true;
        console.error('Error occurred:', error);
      }
    },
    closeCloneModal() {
      this.cloneModal = false;
      this.startCreating();
    },
    cloneMission() {
      this.cloneModal = false;
      this.isMissionCloneModal = true;
    },
    closeMissionCloneModal() {
      this.isMissionCloneModal = false;
    },
    async setCloneData(achievementData) {
      this.initialize();

      this.settingsData = {
        name: achievementData.name,
        tags: achievementData.tags,
        description: achievementData.description,
        // termsAndConditions: achievementData.termsAndConditions,
        icon: achievementData.icon,
        banner: achievementData.banner,
        bannerLowResolution: achievementData.bannerLowResolution,
        bannerHighResolution: achievementData.bannerHighResolution,
        addConstraints: achievementData.addConstraints,
        metadata: achievementData.metadata,
      };

      // Scheduling
      this.schedulingData.scheduleType = achievementData.scheduling.scheduleType;
      this.schedulingData.scheduleOccurrencesLimit = achievementData.scheduling.scheduleOccurrencesLimit;
      this.schedulingData.startDate = achievementData.scheduling.startDate;
      this.schedulingData.endDate = achievementData.scheduling.endDate;

      if (achievementData.maxNumberOfIssues) {
        this.schedulingData.maxNumberOfIssues = achievementData.maxNumberOfIssues;
      }

      // Rules
      this.rulesQueryData = this.getCloneRulesData(achievementData.rules);

      for (let key in this.rulesQueryData) {
        if (this.rulesQueryData[key]) {
          this.rulesData[key] = this.rulesQueryData[key];
        }
      }

      // Strategies
      this.strategies = cloneDeep(achievementData.strategies);

      // Entrants
      if (achievementData.memberTagsFilter) {
        const keyArray = Object.values(achievementData.memberTagsFilter)
          .flat()
          .filter(e => typeof e === 'string' && e !== '');

        const data = await this.handleGetTagsByQuery({
          queryRequest: {
            must: [
              {
                queryField: 'key',
                queryValues: keyArray
              }
            ],
            limit: keyArray.length
          }
        });

        const selectedData = data.map(item => ({ ...item, valid: true }));

        const canParticipate = !keyArray.length;

        const must = this.getCurrentTypeValue(achievementData.memberTagsFilter.must, 'must');
        const mustNot = this.getCurrentTypeValue(achievementData.memberTagsFilter.mustNot, 'mustNot');
        const should = this.getCurrentTypeValue(achievementData.memberTagsFilter.should, 'should');

        const currentTypes = { ...must, ...should, ...mustNot };

        const formData = {
          maxNumberOfEntrants: achievementData.maxNumberOfEntrants ?? null,
          minNumberOfEntrants: achievementData.minNumberOfEntrants ?? 1,
          must: achievementData.memberTagsFilter.must,
          mustNot: achievementData.memberTagsFilter.mustNot,
          should: achievementData.memberTagsFilter.should,
          shouldMatchAtLeast: achievementData.memberTagsFilter.shouldMatchAtLeast
        };
        const limitParticipants = !![
          ...achievementData.memberTagsFilter.must,
          ...achievementData.memberTagsFilter.mustNot,
          ...achievementData.memberTagsFilter.should
        ].length;
        const optinRequiredForEntrants = achievementData.constraints.includes('optinRequiredForEntrants');

        this.updateEntrantsData({
          canParticipate,
          selectedData,
          currentTypes,
          formData,
          limitParticipants,
          optinRequiredForEntrants
        });
      }

      // Rewards
      if (achievementData.rewards.length) {
        const cloneRewards = cloneDeep(achievementData.rewards);
        const rewardTypeIds = cloneRewards.map(reward => reward.rewardTypeId);
        const rewardTypes = await this.handleGetRewardTypes({ idArray: rewardTypeIds });
        cloneRewards.forEach(reward => {
          reward.rewardType = rewardTypes.find(rewardType => rewardType.id === reward.rewardTypeId);
        });
        this.updateRewardData(cloneRewards);
      }

      // Products
      if (achievementData.productIds.length) {
        this.productsData.selectedData = await this.handleGetProducts({ idArray: achievementData.productIds });
        this.productsData.isAllProductsInclude = false;
      } else if (achievementData.productTagsFilter && Object.keys(achievementData.productTagsFilter).length) {
        const keyArray = [
          ...achievementData.productTagsFilter.must,
          ...achievementData.productTagsFilter.mustNot,
          ...achievementData.productTagsFilter.should
        ];

        this.productsData.selectedData = await this.handleGetTagsByQuery({
          queryRequest: {
            must: [
              {
                queryField: 'key',
                queryValues: keyArray
              }
            ],
            limit: keyArray.length
          }
        });

        this.productsData.isAllProductsInclude = false;
        this.productsData.formData.shouldMatchAtLeast = achievementData.productTagsFilter.shouldMatchAtLeast;
        this.productsData.formData.dependantOn.must = achievementData.productTagsFilter.must;
        this.productsData.formData.dependantOn.mustNot = achievementData.productTagsFilter.mustNot;
        this.productsData.formData.dependantOn.should = achievementData.productTagsFilter.should;
      }

      // Translations
      if (achievementData.translations) {
        const languages = await this.handleGetLanguages({ idArray: [] });
        const map = this.getMapLanguages(languages);

        const achTranslations = achievementData.translations.map(item => {
          const translations = item.translations.reduce((acc, translation) => {
            return { ...acc, [translation.fieldName]: translation.text };
          }, {});
          return { languageKey: item.languageKey, translations: translations };
        }).reduce((obj, item) => {
          obj[item['languageKey']] = item.translations;
          return obj;
        }, {});

        const val = languages.reduce((acc, lang) => {
          const language = achTranslations[lang.key]
            ? { [lang.name]: achTranslations[lang.key] }
            : { [lang.name]: {} };
          return { ...acc, ...language };
        }, {});

        this.updateTranslationsData({ map, val });
      }

      this.isStartCreating = true;
    },
    getCurrentTypeValue(arr, typeValue) {
      return arr.reduce((accumulator, value) => {
        return { ...accumulator, [value]: typeValue };
      }, {});
    },
    getMapLanguages(languages) {
      return languages.reduce((acc, language) => {
        const lang = { [language.name]: language.key };
        return { ...acc, ...lang };
      }, {});
    },
    getCloneRulesData(rules) {
      let query = {};
      if (rules.length) {
        rules.forEach(action => {
          for (let key in action.rules) {
            if (action.rules[key] === null) {
              if (key === 'rules') {
                action.rules[key] = [];
              } else {
                action.rules[key] = '';
              }
            } else if (key === 'rules') {
              action.rules[key] = action.rules[key].map(r => this.cleanRules(r));
            } else if (key === 'then' && action.rules[key] !== null && action.rules[key].length) {
              action.rules[key] = action.rules[key].map(t => this.cleanThen(t));
            }
          }
          query[action.action] = action.rules;
        });
      }

      return query;
    },
    cleanRules(rules) {
      if (rules.then && rules.then.length) {
        rules.then = rules.then.map(t => this.cleanThen(t));
      }
      if (rules.rules === null) {
        rules.rules = [];
      } else if (rules.rules.length) {
        rules.rules = rules.rules.map(r => this.cleanRules(r));
      }

      return rules;
    },
    cleanThen(then) {
      if (then.arguments === null) {
        then.arguments = [];
      }

      return then;
    },
    addConnection(obj) {
      switch (obj.type) {
        case 'must': {
          this.dependantOnData.formData.dependantOn.must.push(obj.missionId);
          break;
        }
        case 'should': {
          this.dependantOnData.formData.dependantOn.should.push(obj.missionId);
          break;
        }
        case 'mustNot': {
          this.dependantOnData.formData.dependantOn.mustNot.push(obj.missionId);
          break;
        }
      }
      this.dependantOnData.isMission = false;
      this.dependantOnData.selectedData = [this.missions.find(item => item.id === obj.missionId)];
      this.cloneModal = true;
    },
    resetState() {
      this.steps = [];
      this.rulesQueryData = {};
      this.rulesData = cloneDeep(achievementQuery)
      this.strategies = {
        pointsStrategy: {
          operator: 'GreaterThanEquals',
            pointsValueUpper: null,
            pointsValue: 100
        }
      }
      this.entrantsData = {
        currentTypes: {},
        selectedData: [],
        limitParticipants: false,
        canParticipate: true,
        optinRequiredForEntrants: false,
        formData: {
          maxNumberOfEntrants: null,
          minNumberOfEntrants: 0,
          shouldMatchAtLeast: 1,
          must: [],
          mustNot: [],
          should: []
        },
      };
      this.schedulingData = {
        scheduleOccurrencesLimit: 1,
        maxNumberOfIssues: null,
        scheduleType: '',
        constraints: [],
        endDate: null,
        startDate: new Date(),
        every: [],
      };
      this.productsData = {
        currentTypes: {},
        isAllProductsInclude: true,
        selectedData: [],
        formData: {
          shouldMatchAtLeast: 1,
          dependantOn: {
            must: [],
            mustNot: [],
            should: []
          }
        }
      };
      this.dependantOnData = {
        isMission: true,
        currentTypes: {},
        formData: {
          shouldMatchAtLeast: null,
          dependantOn: {
            must: [],
            mustNot: [],
            should: [],
            shouldMatchAtLeast: 0,
          }
        }
      };
      this.formList = [];
      this.rewardsData = [];
      this.settingsData = {};
      this.translationsData = {};
      this.translationsMap = {};
      this.currentStep = 0;
      this.totalStep = 0;
      this.isCompleted = false;
    },
    showCancelModal() {
      this.cancelMissionCreationModal = true;
    },
    closeCancelModal() {
      this.cancelMissionCreationModal = false;
    },
    handleCancel() {
      this.cancelMissionCreationModal = false;
      this.resetState();
      this.isStartCreating = false;
    },
    handleDoFunction() {
      if (this.cloneModal) {
        this.cloneMission();
      } else {
        this.handleCancel();
      }
    },
    handleToggleModal() {
      if (this.cloneModal) {
        this.closeCloneModal();
      } else {
        this.closeCancelModal();
      }
    },
    closeModal() {
      this.cancelMissionCreationModal = false;
      this.cloneModal = false;
      this.isMissionCloneModal = false;
    },
    finishMissionCreation() {
      this.$router.push({
        name: 'PreviewMission',
        params: {
          id: this.missionId,
        }
      });
    }
  }
};
</script>


<style scoped lang="scss">

.create-page {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: calc(100vh - 110px);

  .loader {
    z-index: 1500;
  }

  .zq-invalid {
    .form-control {
      border: solid 1px var(--zq-warn);
    }
  }

  .zq--custom-button {

    &:hover:not([disabled]) {
      opacity: 0.9;
      box-shadow: 0 1px 10px 0 rgba(150, 109, 103, 0.1);
      border: solid 1px #282f37;
      background-color: #007DFB;
      color: #ffffff;
    }

    &:focus {
      box-shadow: none;
      border: solid 1px #282f37;
      background-color: #ffffff;
      color: #282f37;
    }
  }

  .creating-step-wrapper {
    width: 100%;
    height: 100%;
    overflow-x: auto;
    overflow-y: auto;

    &::-webkit-scrollbar {
      height: 3px;
      width: 3px;
      background-color: #f0f0f0;
    }
  }

  .zq-invalid {
    .scheduleType-wrapper {
      .zq--select {
        .multiselect-wrapper {

          .multiselect-zq-wrap {

          }
        }

      }

      //border: solid 1px var(--zq-warn);
    }
  }

  .click-to-start {
    position: absolute;
    width: 187px;
    height: 122px;
    top: 252px;
    left: 404px;
    border-radius: 50%;
    background-color: #EE3EC8;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .finish-btn {
    position: absolute;
    right: 20px;
    top: 80px;
    z-index: 10000;
  }

  .mission-block {
    position: absolute;
    top: 252px;
    left: 304px;
  }

  .creating-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 70px;
    right: 0;
    z-index: 1000;
    background-color: #f1f2f4;
    width: 60%;
    height: 88%;
    border-radius: 20px 0 0 20px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2),
    10px 10px 20px rgba(0, 0, 0, 0.2),
    15px 15px 30px rgba(0, 0, 0, 0);

    transform: translateX(100%);
    transition: none;

    .create-form {
      .form-content {
        border-top: none !important;
      }
    }


    &_header {
      width: 100%;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;

      &_title {
        color: rgba(0, 0, 0, 0.6);
        font-size: 22px;
        font-weight: 400;
        line-height: 33px;
        text-align: center;

      }

      .warning {
        font-size: 16px;
        font-weight: 400;
        color: red;
      }

      &_actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        //width: 27%;
      }
    }
  }

  .creating-block.visible {
    animation: slideIn 0.4s ease-in-out forwards;
  }

  .creating-block.hidden {
    animation: slideOut 0.4s ease-in-out forwards;
  }

  @keyframes slideIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes slideOut {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(100%);
    }
  }
}


</style>
