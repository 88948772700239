import { render, staticRenderFns } from "./SettingsStep.vue?vue&type=template&id=9398d16a&scoped=true"
import script from "./SettingsStep.vue?vue&type=script&lang=js"
export * from "./SettingsStep.vue?vue&type=script&lang=js"
import style0 from "./SettingsStep.vue?vue&type=style&index=0&id=9398d16a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9398d16a",
  null
  
)

export default component.exports