<template>
  <div class="settings-step">
              <!--    Settings-->
    <div class="d-flex align-items-center w-100 name-tag-wrapper pr-0">
      <CIInputCol
        class="col-6"
        :label="getLabel('name')"
        :tooltip="getTooltipByName('name')"
        v-model="settingsDataLocal.name"
        :placeholder="'name'"
        :required="true"
        :name="'name'"
      />
      <CITagField
        :type="'Achievement'"
        class="col-6 pl-0 instant-win-tag"
        :is-column="true"
        v-model="settingsDataLocal.tags"
        :tooltip="getTooltipByName('tags')"
      />
    </div>
    <div class="d-flex align-items-center w-100 name-tag-wrapper pr-0">
      <CITextArea
        class="col-12"
        :is-column="true"
        :label="getLabel('description')"
        :tooltip="getTooltipByName('description')"
        v-model="settingsDataLocal.description"
        :placeholder="'description'"
        :name="'description'"
        :isToolbar="true"
      />
    </div>

    <div class="d-flex align-items-center w-100">
      <CIIconField
        ref="iconRef"
        class="w-100"
        v-model="settingsDataLocal.icon"
        :label="getLabel('icon')"
        @deleteFile="handleDeleteIconImageFile"
        :isColumn="true"
        :isPopup="true"
      />
      <CIIconField
        ref="bannerRef"
        class="w-100 pl-2"
        v-model="settingsDataLocal.banner"
        :label="getLabel('banner')"
        @deleteFile="handleDeleteBannerImageFile"
        :isColumn="true"
        :isPopup="true"
      />
    </div>

    <div class="d-flex align-items-center w-100">
      <CIIconField
        ref="bannerLowResolutionRef"
        class="w-100"
        v-model="settingsDataLocal.bannerLowResolution"
        :label="getLabel('bannerLowResolution')"
        @deleteFile="handleDeleteBannerLowResolutionFile"
        :isColumn="true"
        :isPopup="true"
      />
      <CIIconField
        ref="bannerHighResolutionRef"
        class="w-100 pl-2"
        v-model="settingsDataLocal.bannerHighResolution"
        :label="getLabel('bannerHighResolution')"
        @deleteFile="handleDeleteBannerHighResolutionFile"
        :isColumn="true"
        :isPopup="true"
      />
    </div>

    <div class="d-flex align-items-center w-100">
      <div class="d-flex flex-column w-50 pr-4">
        <span class="tag-label-text">{{ texts.createPage.schedulingStep.startLabel }}</span>
        <DatePicker
          :fieldName="'scheduledStartDate'"
          :dateProp="startDate"
          @input="setStartDate"
          :placeholder="texts.createPage.schedulingStep.startPlaceholder"
          :isValid="fromValidate !== false"
          class="schedule-startDate date-picker"
          :disabled="isFieldDisabled"
          :disabledDate="disabledStartDate"
        />
      </div>

      <div class="d-flex flex-column w-50 pl-2">
        <span class="tag-label-text">{{ texts.createPage.schedulingStep.endDateLabel }}</span>
        <DatePicker
          :fieldName="'scheduledEndDate'"
          :dateProp="endDate"
          @input="setEndDate"
          :placeholder="texts.createPage.schedulingStep.startPlaceholder"
          :isValid="fromValidate !== false"
          class="schedule-startDate date-picker"
          :disabled="isFieldDisabled"
          :disabledDate="disabledStartDate"
        />
      </div>
    </div>

    <div class="d-flex align-items-center w-100 pt-3 pb-3">
      <div class="d-flex flex-column w-50 pr-4">
        <span class="tag-label-text">{{ texts.createPage.schedulingStep.scheduleTypeLabel }}</span>
        <div class="scheduleType-wrapper">
          <ClSelect
            name="scheduleType"
            class="text-capitalize"
            :options="occurrencesLimitOption"
            :placeholder="texts.createPage.schedulingStep.scheduleTypePlaceholder"
            :valueProp="schedulingDataLocal.scheduleType"
            size="xxl"
            required
            :selectLabel="selectLabel"
            :invalidFeedback="texts.invalidFeedback"
            :is-valid="scheduleTypeValidate"
            :disabled="isFieldDisabled"
            @checkValue="scheduleTypeUpdate"
          />
        </div>

      </div>
      <!--    When    -->
      <div class="d-flex flex-column w-50 pr-3" v-if="isEveryVisible">
        <span class="tag-label-text">{{ texts.createPage.schedulingStep.whenLabel }}</span>
        <multiselect
          :class="selectErrorClass"
          v-model="everyChecked"
          :options="every"
          label="label"
          track-by="value"
          :multiple="true"
          :disabled="isFieldDisabled"
          :selectLabel="selectLabel"
          :tagPlaceholder="tagPlaceholder"
          :preserve-search="true"
          :hide-selected="true"
          class="custom-multi-select zq--multiselect"
        >
          <template #caret>
            <div></div>
          </template>
        </multiselect>
      </div>

      <!--  Schedule Occurrences Limit      -->
      <div class="d-flex flex-column w-50" v-if="isEveryVisible || schedulingDataLocal.scheduleType === 'Repeatedly'">
        <span class="tag-label-text">{{ texts.createPage.schedulingStep.occurrencesLabel }}</span>
        <CInput
          type="number"
          :placeholder="texts.createPage.schedulingStep.occurrencesPlaceholder"
          v-model.number="schedulingDataLocal.scheduleOccurrencesLimit"
          add-input-classes="col-sm-12"
          min="1"
          step="1"
          :disabled="isFieldDisabled"
          @update:value="handleUpdateLimit"
          :invalidFeedback="texts.invalidFeedback"
          :isValid="scheduleOccurrencesLimitValidate"
        />
      </div>

    </div>

    <div class="d-flex  w-100 pt-3 pb-3">
      <div class="d-flex flex-column w-50">
        <span class="tag-label-text">{{ texts.createPage.schedulingStep.maxNumberOfIssues }}</span>
        <div class="d-flex align-items-center" style="height: 40px;">
          <CSwitch
            color="success"
            shape="pill"
            :value="isMaxNumberOfIssues"
            :checked="isMaxNumberOfIssues"
            class=""
            :labelOn="'on'"
            :labelOff="'off'"
            :disabled="isFieldDisabled"
            @update:checked="maxNumberOfIssuesUpdate"
          />
          <IconWithTooltip class="ml-2" :text="descriptions.autoStart"/>
        </div>
      </div>

      <div class="d-flex flex-column w-50" v-if="isMaxNumberOfIssues">
        <span class="tag-label-text">{{ texts.createPage.schedulingStep.maxNumberOfIssues }}</span>
        <CInput
          name="maxNumberOfIssues"
          type="number"
          v-model.number="schedulingDataLocal.maxNumberOfIssues"
          add-input-classes="col-sm-12"
          min="1"
          step="1"
          :disabled="isFieldDisabled"
          :invalidFeedback="texts.tooLargeValue"
        />
      </div>
    </div>
                <!--    Entrants-->
    <div class="d-flex align-items-center w-100 pt-3 pb-3">
      <div class="d-flex align-items-center w-75">
        <div class="d-flex flex-column w-25">
          <span class="tag-label-text">{{ texts.createPage.entrants.requireLabel }}</span>
          <div class="d-flex align-items-center">
            <CSwitch
              color="success"
              shape="pill"
              :value="optinRequiredForEntrants"
              :checked="optinRequiredForEntrants"
              class="zq--switch"
              :labelOn="'on'"
              :labelOff="'off'"
              :disabled="isFieldDisabled"
              @update:checked="optInUpdate"
            />
            <IconWithTooltip class="ml-2" :text="descriptions.optInRequired"/>
          </div>
        </div>
        <div class="d-flex flex-column w-50 pl-5">
          <span class="tag-label-text">{{ texts.createPage.summary.entrants.everyoneText }}</span>
          <div class="d-flex align-items-center">
            <CSwitch
              color="success"
              shape="pill"
              :value="canParticipate"
              :checked="canParticipate"
              class="zq--switch"
              :labelOn="'on'"
              :labelOff="'off'"
              :disabled="isFieldDisabled"
              @update:checked="canParticipateUpdate"
            />
            <IconWithTooltip class="ml-2" :text="descriptions.autoStart"/>
          </div>
        </div>
      </div>
    </div>

    <!--    ESelectTable    -->
    <div class="d-flex align-items-center w-100 pt-3 pb-0">
      <!--   Restrict members to  -->
      <DepResultEntrantsTable
        class="w-100"
        v-if="!canParticipate && !isSelectTableVisible"
        @toSelectTable="toSelectTable"
        @updateCurrentTypes="updateCurrentTypes"
        @updateData="handleUpdateEntrantsData"
        @resetValidation="resetEntrantsValidation"
        :currentTypesProps="currentTypes"
        :isValid="isValid"
        :propData="entrantsData"
        :disabled="isFieldDisabled"
        :toolTipText="descriptions.minimumShouldMatch"
        :fields="resultsTableFields"
      />
      <!--    ESelectTable    -->
      <DepSelectEntrantsTable
        class="w-100"
        v-if="isSelectTableVisible"
        @toResultTable="toResultTable"
        @updateSelectData="tagsSelectData"
        :fields="fields"
        storeType="tags"
        entityType="Member"
        :title="texts.createPage.entrants.selectTitle"
        :selectedData="selectedData"
        :formData="entrantsFormData"
      />
    </div>

    <div class="d-flex align-items-center">
      <CIMetaDataField
        class="mt-0"
        v-model="settingsDataLocal.metadata"
        :label="getLabel('metadata')"
        :tooltip="getTooltipByName('metadata')"
        :placeholder="'placeholder'"
        :colSm="'5'"
        :colMd="'6'"
        :colLg="'5'"
        :colXl="'5'"
      />
    </div>


  </div>
</template>

<script>
import CIInputCol from '@/shared/components/formComponents/CIInputCol.vue';
import CITagField from '@/shared/components/CITagField.vue';
import { cloneDeep, range, startCase } from 'lodash';
import CITextArea from '@/shared/components/formComponents/CITextArea.vue';
import CIMetaDataField from '@/shared/components/CIMetaDataField.vue';
import CIIconField from '@/shared/components/CIIconField/CIIconField.vue';
import DatePicker from '@/shared/components/DatePicker';
import { isAchFieldDisabled } from '@/utils/validationUtils';
import { mapActions, mapGetters } from 'vuex';
import ClSelect from '@/shared/components/formComponents/ClSelect.vue';
import ClFormRow from '@/shared/components/formComponents/ClFormRow.vue';
import { formConfig } from '@/config';
import SwitchRow from '@/shared/components/SwitchRow.vue';
import IconWithTooltip from '@/shared/UI/IconWithTooltip.vue';
import DepSelectEntrantsTable from '@/shared/components/DepTables/DepSelectEntrantsTable.vue';
import DepResultEntrantsTable from '@/shared/components/DepTables/DepResultEntrantsTable.vue';

export default {
  name: 'SettingsStep',
  components: {
    DepResultEntrantsTable,
    DepSelectEntrantsTable,
    IconWithTooltip,
    SwitchRow,
    ClFormRow, ClSelect,
    CIIconField,
    CIMetaDataField,
    CITextArea,
    CITagField,
    CIInputCol,
    DatePicker
  },
  props: {
    list: [Array],
    descriptions: Object,
    texts: Object,
    settingsData: Object,
    schedulingData: Object,
    entrantsData: Object,
    isValid: Boolean,
  },
  data() {
    return {
      settingsDataLocal: {
        name: '',
        tags: [],
        description: '',
        metadata: null,
        icon: '',
        banner: '',
        bannerLowResolution: '',
        bannerHighResolution: '',
      },
      startDate: '',
      endDate: null,
      schedulingDataLocal: {
        scheduleType: '',
        scheduleOccurrencesLimit: 1,
        maxNumberOfIssues: null,
        every: [],
        startDate: '',
        endDate: null,
        onlyAggregateOnActiveDays: false
      },
      everyDay: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      everyMonth: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      occurrencesLimitOption: ['Once', 'Repeatedly', 'Daily', 'Weekly', 'Monthly'],
      entrantsFormData: {
        maxNumberOfEntrants: null,
        minNumberOfEntrants: null,
        shouldMatchAtLeast: 1,
        must: [],
        mustNot: [],
        should: []
      },
      fields: [
        "select",
        "id",
        "created",
        "name",
        // "key",
        "description",
      ],
      resultsTableFields: [
        {key: 'id', sorter: false},
        {key: 'name', sorter: false},
        {key: 'dependency', sorter: false},
      ],
      currentTypes: {},
      isSelectTableVisible: false,
      optinRequiredForEntrants: false,
      canParticipate: true,
      selectedData: [],
      selectedEntrantsData: {},
      everyChecked: [],
      invalidEndDate: false,
      fromValidate: null,
      selectLabel: formConfig.selectLabel,
      tagPlaceholder: formConfig.tagPlaceholder,
      scheduleTypeValidate: null,
      everyValidate: null,
      scheduleOccurrencesLimitValidate: null,
      showMaxNumberOfIssues: false,
      isMaxNumberOfIssues: false,
      constraints: [],
    };
  },
  created() {
    this.initialize();
    if (this.schedulingData.startDate) {
      const startDate = new Date(this.schedulingData.startDate);
      this.startDate = new Date(startDate.getTime() + startDate.getTimezoneOffset() * 60000);
    }

    if (this.schedulingData.endDate) {
      const endDate =  new Date(this.schedulingData.endDate);
      this.endDate = new Date(endDate.getTime() + endDate.getTimezoneOffset() * 60000);
    }

    if (this.schedulingData.maxNumberOfIssues) this.isMaxNumberOfIssues = true;

    this.everyChecked = this.schedulingData.every.map((item) => {
      return this.every[item - 1]
    });
  },
  watch: {
    settingsDataLocal: {
      deep: true,
      handler: function (val) {
        this.$emit('updateSettingsData', val);
      }
    },
    schedulingDataLocal: {
      deep: true,
      handler: function (val) {
        this.$emit('updateSchedulingData', val);
      }
    },
    everyChecked: {
      deep: true,
      handler: function (val) {
        this.schedulingDataLocal.every = val.reduce((acc, item) => {
          return [
            ...acc,
            item.value
          ]
        }, []);
      }
    },
    optinRequiredForEntrants(val) {
      this.updateConstraints(val, 'optinRequiredForEntrants');
    },
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    every() {
      if (this.schedulingDataLocal.scheduleType === 'Daily') return this.arrayToEvery(this.everyDay);
      if (this.schedulingDataLocal.scheduleType === 'Monthly') return this.arrayToEvery(this.everyMonth);
      if (this.schedulingDataLocal.scheduleType === 'Weekly') return this.weeklyToEvery(range(1, 53));

      return null;
    },
    isEveryVisible() {
      return this.schedulingDataLocal.scheduleType === 'Daily'
        || this.schedulingDataLocal.scheduleType === 'Monthly'
        || this.schedulingDataLocal.scheduleType === 'Weekly';
    },
    isFieldDisabled() {
      return isAchFieldDisabled(this.achievementType);
    },
    selectErrorClass() {
      const className = this.theme === 'main' ? 'error-validation--multiselect' : 'error-validation';

      return {
        [className]: this.everyValidate
      };
    }
  },
  methods: {
    ...mapActions('members', { handleGetTags: 'handleGetMembers' }),
    initialize() {
      if (this.settingsData) {
        this.settingsDataLocal = {
          ...this.settingsDataLocal,
          ...this.settingsData,
        };
      }

      this.schedulingDataLocal = this.schedulingData;

      this.entrantsFormData = cloneDeep(this.entrantsData.formData);

      this.limitParticipants = this.entrantsData.limitParticipants;
      this.canParticipate = this.entrantsData.canParticipate;
      this.optinRequiredForEntrants = this.entrantsData.optinRequiredForEntrants;
      this.currentTypes = cloneDeep(this.entrantsData.currentTypes);

      this.selectedData = cloneDeep(this.entrantsData.selectedData);
    },
    getTooltipByName(name) {
      return this.descriptions[name];
    },
    getLabel(fieldName) {
      const foundObject = this.list.find(item => item.key === fieldName);

      if (foundObject) {
        return foundObject.required ? startCase(foundObject.label) + '*' : startCase(foundObject.label);
      }
    },
    handleDeleteIconImageFile() {
      this.settingsDataLocal.icon = '';
    },
    handleDeleteBannerImageFile() {
      this.settingsDataLocal.banner = '';
    },
    handleDeleteBannerHighResolutionFile() {
      this.settingsDataLocal.bannerHighResolution = '';
    },
    handleDeleteBannerLowResolutionFile() {
      this.settingsDataLocal.bannerLowResolution = '';
    },
    handleUpdateLimit(val) {
      if (val > 2147483647) this.schedulingDataLocal.scheduleOccurrencesLimit = 2147483647;
      if (!val || val < 1) {
        this.schedulingDataLocal.scheduleOccurrencesLimit = 1;
      } else {
        this.schedulingDataLocal.scheduleOccurrencesLimit = parseInt(val)
      }
    },
    setStartDate(date) {
      const isoDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();

      this.schedulingDataLocal.startDate = date ? isoDate : '';
      this.startDate = date;
    },
    disabledStartDate(d) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return d < new Date(new Date().setHours(0, 0, 0, 0));
    },
    setEndDate(date) {
      if (!date) return;
      this.invalidEndDate = false;
      let invalidFieldElement = document.getElementsByName('scheduledEndDate')[0];
      let invalidElement = invalidFieldElement;
      if (!invalidFieldElement.classList.contains('zq--form-row')) {
        invalidElement = invalidFieldElement.parentNode;
      }
      invalidElement.classList.remove('zq-invalid');

      const isoDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();

      this.schedulingDataLocal.endDate = date ? isoDate : null;
      this.endDate = date;
    },
    arrayToEvery(array) {
      return array.reduce((acc, item, index) => {
        return [
          ...acc,
          {
            value: index + 1,
            label: item
          }
        ]
      }, []);
    },
    weeklyToEvery(array) {
      return array.reduce((acc, item) => {
        return [
          ...acc,
          {
            value: item,
            label: `week ${item}`
          }
        ]
      }, []);
    },
    scheduleTypeUpdate({value}) {
      if (value === 'Once') {
        this.schedulingDataLocal.scheduleOccurrencesLimit = 1;
      }

      this.schedulingDataLocal.scheduleType = value;
      if (!['Daily', 'Weekly', 'Monthly'].includes(value)) {
        this.everyChecked = [];
      } else {
        switch (value) {
          case 'Daily':
            if (this.everyChecked.length) {
              if (!this.everyDay.includes(this.everyChecked[0].label)) {
                this.everyChecked = [];
                this.schedulingDataLocal.scheduleOccurrencesLimit = 1;
              }
            }
            return;
          case 'Weekly':
            if (this.everyChecked.length) {
              if (!this.everyChecked[0].label.includes('week')) {
                this.everyChecked = [];
                this.schedulingDataLocal.scheduleOccurrencesLimit = 1;
              }
            }
            return;
          case 'Monthly':
            if (this.everyChecked.length) {
              if (!this.everyMonth.includes(this.everyChecked[0].label)) {
                this.everyChecked = [];
                this.schedulingDataLocal.scheduleOccurrencesLimit = 1;
              }
            }
            return;
        }
      }

      this.$emit('resetValidate');
    },
    maxNumberOfIssuesUpdate(val) {
      this.isMaxNumberOfIssues = val;
    },
    optInUpdate(val) {
      this.optinRequiredForEntrants = val;
    },
    updateConstraints(val, constraint) {
      if (val) {
        if (!this.constraints.includes(constraint)) {
          this.constraints.push(constraint);
        }
      } else {
        this.constraints = this.constraints.filter(item => item !== constraint);
      }
      this.$emit('updateConstraints', this.constraints);
    },
    canParticipateUpdate(val) {
      this.canParticipate = val;
      this.resetCanParticipate(val)
      this.$emit('updateCanParticipate', val)
    },
    resetCanParticipate(val) {
      if (val) {
        const dependantFormData = {
          shouldMatchAtLeast: 1,
          must: [],
          mustNot: [],
          should: []
        }
        this.entrantsFormData = {...this.entrantsFormData, ...dependantFormData};

        this.$emit('updateEntrantsData', {
          ...this.entrantsFormData,
          formData: this.entrantsFormData,
          selectedData: []
        })
      }
    },
    limitParticipants(val) {
      if (val) {
        this.entrantsFormData.maxNumberOfEntrants = 40;
      } else {
        this.entrantsFormData.maxNumberOfEntrants = null;
        this.entrantsFormData.minNumberOfEntrants = null;
      }
      this.$emit('updateLimitParticipants', val)
    },
    toSelectTable() {
      this.isSelectTableVisible = true;
    },
    toResultTable() {
      this.isSelectTableVisible = false;
    },
    tagsSelectData({selectedData, formData}) {
      selectedData.map((item) => {
        item.valid = formData.mustNot.includes(item.id) || formData.must.includes(item.id) || formData.should.includes(item.id);
        return item;
      })
      this.selectedData = selectedData;

      this.entrantsFormData = formData;
      this.$emit('updateEntrantsData', {...this.entrantsData, formData, selectedData});
    },
    updateCurrentTypes(val) {
      this.currentTypes = val;
      this.$emit('updateCurrentTypes', val)
    },
    handleUpdateEntrantsData(val) {
      this.selectedData = val.selectedData;
      this.entrantsFormData = val.formData;
      this.$emit('updateEntrantsData', {...this.entrantsData, formData: val.formData, selectedData: val.selectedData});
    },
    resetEntrantsValidation() {
      this.$emit('resetEntrantsValidation');
    },
  }
};
</script>

<style scoped lang="scss">
.settings-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }
}

.c-switch {
  position: relative;
  .c-switch-input .form-check-input {
    position: absolute;
  }
}

.form-group {
  margin: 0;
}

.c-nxt-app__main-theme .zq--data-picker.mx-datepicker .mx-input-wrapper .mx-icon-calendar {
  right: 26px;
  width: 200px;
}

.zq-invalid .form-control {
  border: 1px solid red !important;
}

.tag-label-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

</style>
